import { SVGProps } from "react";
import { useTheme } from "@mui/material";

export interface ILogoProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

export default function Logo({ size = 1.5, ...props }: ILogoProps) {
  const theme = useTheme();
  return (
    <svg
      width={Math.round(20 * size)}
      height={Math.round(20 * size)}
      viewBox="0 0 85 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="glue-wiki-logo-title"
      role="img"
      {...props}
    >
      <path
        d="M42.3657 17.3907L71.7118 68.11C72.8513 70.0794 72.1751 72.5999 70.2029 73.7345L56.5671 81.5793C54.5803 82.7223 52.0425 82.0206 50.9252 80.0192L42.3657 64.6869L33.6202 80.0541C32.4905 82.0392 29.9596 82.7235 27.9834 81.5781L14.4616 73.7408C12.4965 72.6018 11.8267 70.0855 12.9656 68.1204L42.3657 17.3907Z"
        fill="#0F6AFF"
      />
      <path
        d="M62.7137 52.889L4.11656 52.9438C1.84121 52.9459 -0.00348223 51.1001 4.93593e-06 48.8248L0.0241075 33.0935C0.0276204 30.8013 1.90427 28.9544 4.19617 28.9875L21.754 29.2409L12.8184 13.9835C11.6641 12.0125 12.337 9.47855 14.317 8.33985L27.8652 0.548229C29.8341 -0.584111 32.3482 0.0940215 33.4806 2.06291L62.7137 52.889Z"
        fill="#0F6AFF"
      />
      <path
        d="M21.6736 52.9473L50.9247 2.17322C52.0606 0.201644 54.5814 -0.473008 56.5502 0.667688L70.1619 8.55423C72.1452 9.70334 72.8063 12.252 71.6317 14.2203L62.6334 29.2992L80.3145 29.1895C82.5985 29.1753 84.4565 31.0249 84.4527 33.309L84.4263 48.9379C84.4225 51.2093 82.5782 53.0475 80.3069 53.0437L21.6736 52.9473Z"
        fill="#0F6AFF"
      />
    </svg>
  );
}
